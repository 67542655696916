import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import ContentEditor from "./pages/Content-Editor";
import BusinessRuleEngine from "./pages/BusinessRuleEngine";
import TaskFlows from "./pages/TaskFlows";
import { useSelector } from "react-redux";
import TagsEditor from "./pages/TagsEditor";
import Category from "./pages/Category";
import Achievement from "./pages/Achievement";
import Notification from "./pages/Notification";
import UserLevel from "./pages/UserLevel";
import DiaryQuestions from "./pages/DiaryQuestions";
import Users from "./pages/Users";
import UserManagement from "./components/UserManagement/userManagement";

function App() {
  const auth = useSelector((state) => state.auth.isAuthenticated);

  return (
    <div className="App">
      <Switch>
        {/* <Route path='/sign-in' exact component={SignIn} />
				<Main>
					<Route exact path='/dashboard' component={Home} />
					<Route exact path='/business-rule-engine' component={BusinessRuleEngine} />
					<Route exact path='/content-editor' component={ContentEditor} />
					<Route exact path='/task-flows' component={TaskFlows} />
					<Route exact path='/tags-editor' component={TagsEditor} />
					<Route exact path='/categories' component={Category} />
					<Route exact path='/achievement' component={Achievement} />
					<Route exact path='/notification' component={Notification} />
					<Route exact path='/' component={Home} />
				</Main> */}
        {auth ? (
          <Main>
            <Route exact path="/dashboard" component={Home} />
            <Route exact path="/users" component={Users} />
            <Route
              exact
              path="/business-rule-engine"
              component={BusinessRuleEngine}
            />
            <Route exact path="/content-editor" component={ContentEditor} />
            <Route exact path="/task-flows" component={TaskFlows} />
            <Route exact path="/tags-editor" component={TagsEditor} />
            <Route exact path="/categories" component={Category} />
            <Route exact path="/achievement" component={Achievement} />
            <Route exact path="/notification" component={Notification} />
            <Route exact path="/user-level" component={UserLevel} />
            <Route exact path="/diary-questions" component={DiaryQuestions} />
            <Route exact path="/userManagement" component={UserManagement} />
            <Route exact path="/" component={Home} />
          </Main>
        ) : (
          <>
            <Route path="/" component={SignIn} />
          </>
        )}
      </Switch>
    </div>
  );
}

export default App;
