import React, { useEffect, useState } from "react";
import { Table, Input, Row, Col, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { render } from "@testing-library/react";

const UsersList = ({ users }) => {
  // Suchzustand
  const [searchText, setSearchText] = useState("");
  // Gefilterte Nutzer
  const [filteredUsers, setFilteredUsers] = useState(users);

  // Für Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Spalten für die Tabelle
  const columns = [
    {
      title: "Name",
      key: "name",
      // Wir setzen hier den Wert "firstname + lastname" zusammen
      render: (user) => `${user.firstname} ${user.lastname}`,
      sorter: (a, b) =>
        (a.firstname + a.lastname).localeCompare(b.firstname + b.lastname),
    },
    {
      title: "Geburtsdatum",
      dataIndex: "birthday",
      key: "birthday",
      sorter: (a, b) => (a.birthday || "").localeCompare(b.birthday || ""),
    },
    {
      title: "E-Mail",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Status",
      dataIndex: "subscriptionStatus",
      key: "subscriptionStatus",
      sorter: (a, b) =>
        (a.subscriptionStatus || "").localeCompare(b.subscriptionStatus || ""),
    },
    {
      title: "Typ",
      dataIndex: "subscriptionType",
      key: "subscriptionType",
      sorter: (a, b) =>
        (a.subscriptionType || "").localeCompare(b.subscriptionType || ""),
    },
    {
      title: "User Level",
      dataIndex: "userLevel",
      key: "userLevel",
      sorter: (a, b) => a.userLevel - b.userLevel,
    },
    {
      title: "User XP",
      dataIndex: "experiencePoints",
      key: "experiencePoints",
      sorter: (a, b) => a.experiencePoints - b.experiencePoints,
    },
    {
      title: "Kategorien",
      dataIndex: "categories",
      key: "categories",
      render: (categories) => (<>
        <div>
          {
            categories.map((category, index) => (
              <div key={index}>
                <p>{category.category.title}: {category.level} ({category.earnedXP} Punkte)</p>
              </div>
            ))
          }
        </div>
      </>),
    },
    {
      title: "Geschlecht",
      dataIndex: "gender",
      key: "gender",
      sorter: (a, b) => (a.gender || "").localeCompare(b.gender || ""),
    },
    {
      title: "Erstellt am",
      dataIndex: "createdAt",
      key: "createdAt",
      // Beispielhafte Umwandlung in ein Datum-Format
      render: (createdAt) => createdAt && new Date(createdAt).toLocaleString(),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "Letzter Login",
      dataIndex: "lastLogin",
      key: "lastLogin",
      render: (lastLogin) => lastLogin && new Date(lastLogin).toLocaleString(),
      sorter: (a, b) => new Date(a.lastLogin) - new Date(b.lastLogin),
    },
    {
      title: "Plattform",
      dataIndex: "purchasedInStore",
      key: "purchasedInStore",
    },
    {
      title: "Code",
      dataIndex: "usedPromoCode",
      key: "usedPromoCode",
    },
  ];

  // Filterfunktion
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = users.filter((user) => {
      const fullName = `${user.firstname} ${user.lastname}`.toLowerCase();
      return (
        fullName.includes(value) ||
        (user.email || "").toLowerCase().includes(value) ||
        (user.subscriptionStatus || "").toLowerCase().includes(value) ||
        (user.subscriptionType || "").toLowerCase().includes(value)
      );
    });
    setFilteredUsers(filteredData);
    setCurrentPage(1);
  };

  // Wenn sich die übergebenen Nutzer ändern, Filter zurücksetzen
  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  /**
   * Erzeugt einen CSV-String aus den gefilterten Nutzerdaten.
   * Anschließend wird ein Dateidownload ausgelöst.
   */
  const handleExportCSV = () => {
    if (!filteredUsers || filteredUsers.length === 0) return;

    // Kopfzeile für CSV
    const headers = [
      "Name",
      "Geburtsdatum",
      "E-Mail",
      "Status",
      "Typ",
      "User Level",
      "User XP",
      "Geschlecht",
      "Erstellt am",
      "Letzter Login",
      "Plattform",
      "Code",
    ];

    // Zeilen befüllen
    const rows = filteredUsers.map((user) => {
      return [
        `${user.firstname} ${user.lastname}`,
        user.birthday ?? "",
        user.email ?? "",
        user.subscriptionStatus ?? "",
        user.subscriptionType ?? "",
        user.userLevel ?? "",
        user.experiencePoints ?? "",
        user.gender ?? "",
        user.createdAt ? new Date(user.createdAt).toLocaleString() : "",
        user.lastLogin ? new Date(user.lastLogin).toLocaleString() : "",
        user.purchasedInStore ?? "",
        user.usedPromoCode ?? "",
      ]
        .map((v) => `"${String(v).replace(/"/g, '""')}"`) // Ggf. Werte escapen
        .join(",");
    });

    // Kompletten CSV-String zusammenbauen
    const csvContent = [headers.join(","), ...rows].join("\n");

    // Blob und Download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "users_export.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: "10px" }}>
        <Col xs={8}>
          <Input
            placeholder="Suche nach Name, E-Mail, Status oder Typ"
            value={searchText}
            onChange={handleSearch}
            prefix={<SearchOutlined />}
          />
        </Col>

        {/* Button für CSV-Export */}
        <Col>
          <Button type="primary" onClick={handleExportCSV}>
            CSV Export
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={filteredUsers}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          onChange: (page, size) => {
            setCurrentPage(page);
            setPageSize(size);
          },
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          locale: {
            items_per_page: "/ Seite",
          },
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} von ${total} Nutzern`,
        }}
        rowKey={(user) => user.id}
        scroll={{ x: 1000 }}
      />
    </>
  );
};

export default UsersList;
