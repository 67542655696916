import axios from "axios";
import { refreshToken } from "../services/AuthService";

// import { useHistory } from "react-router-dom";
import { API_BASE_URL } from "../config/config";

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 60000,
});

service.interceptors.request.use(
	(config) => {
		const jwtToken = localStorage.getItem("token");

		if (jwtToken) {
			config.headers['Authorization'] = "Bearer " + jwtToken;
		}

		if (!jwtToken && !config.headers['Authorization']) {
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

service.interceptors.response.use(
	(response) => {
		return response.data;
	},
	async (error) => {
		if (error.status === 401) {

			// try refresh token
			const res = await refreshToken(localStorage.getItem("refreshToken"));

			if (res) {
				localStorage.setItem("token", res.accessToken);
				localStorage.setItem("refreshToken", res.refreshToken);

				// retry the request
				return service.request(error.config);
			}
			else {
				localStorage.removeItem("token");
				localStorage.removeItem("refreshToken");
				window.location.href = "/login";
			}
		}
		return Promise.reject(error);
	}
);

export default service;
